import { SocialSharingTags } from '@/components/molecules/SocialSharingTags';
import SearchTemplate, {
  SearchTemplateProps,
} from '@/components/template/SearchTemplate/SearchTemplate';
import { getEstFuelSavings } from '@/lib/getEstFuelSavings';
import { getMakesBody } from '@/lib/getMakesBody';
import { getSearchMakesAndModels } from '@/lib/getSearchMakesAndModels';
import { getSeoTagParser, SeoTagsParserProps } from '@/lib/seo/seoTags';
import { searchInventoryQuery, seoInventoryQuery } from '@/lib/staticQueries';
import { BasePageProps } from '@/types/page';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export interface SearchPageProps
  extends Omit<SearchTemplateProps, 'h1Tag' | 'searchType'>,
    SeoTagsParserProps {
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
}

export default function Search({
  initialFilters,
  initialSort,
  location,
  locationTitle,
  makes = [],
  models = [],
  years = [],
  bodyTypes = [],
  condition,
  dealer,
  tagType = 'searchPage',
  showNewCheckAvailabilityButton,
  autoCompleteResults,
  estFuelSavingsByType,
  showRebates = true,
  makesBodyTypes,
}: SearchPageProps) {
  const {
    seoMetaObjTitle,
    seoMetaObjDescription,
    seoMetaObjH1,
    seoMetaKeywords,
  } = getSeoTagParser({
    tagType,
    makes,
    models,
    years,
    fuelType: initialFilters.fuelType,
    bodyTypes,
    location,
    locationTitle,
    condition,
    dealer,
    priceEnd: initialFilters.price_end,
  });
  return (
    <main className="flex-grow bg-white">
      <SocialSharingTags
        title={seoMetaObjTitle}
        description={seoMetaObjDescription}
        keywords={seoMetaKeywords}
      />
      <SearchTemplate
        searchType={tagType === 'seoPage' ? 'SEO' : 'Search'}
        initialFilters={initialFilters}
        initialSort={initialSort}
        showRebates={showRebates}
        h1Tag={seoMetaObjH1}
        showNewCheckAvailabilityButton={showNewCheckAvailabilityButton}
        estFuelSavingsByType={estFuelSavingsByType}
        autoCompleteResults={autoCompleteResults}
        makesBodyTypes={makesBodyTypes}
      />
    </main>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const slugArray: string[] =
    typeof context?.params?.slug === 'string'
      ? [context.params.slug]
      : context?.params?.slug ?? [];

  let searchResults;

  const tagType = slugArray[0] === 'search' ? 'searchPage' : 'seoPage';
  if (tagType === 'searchPage') {
    searchResults = await searchInventoryQuery(slugArray.slice(1));
  } else {
    searchResults = await seoInventoryQuery(context, slugArray);
  }

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? 'en', ['common'])),
      ...searchResults,
      tagType,
      makesBodyTypes: await getMakesBody(),
      autoCompleteResults: await getSearchMakesAndModels(),
      showNewCheckAvailabilityButton:
        process.env.NEXT_PUBLIC_ENABLE_NEW_CHECK_AVAILABILITY_BUTTON === 'true',
      estFuelSavingsByType: await getEstFuelSavings(),
    } satisfies BasePageProps<SearchPageProps>,
  };
};
